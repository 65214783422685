/**
 * 시간 제한 표기
 * @param {number} time_limit_in_seconds 시간 제한
 * @returns {string} 시간 제한 문자열
 */
export const timeLimitString = function (timeLimitInSeconds: number): string {
    return `${String(timeLimitInSeconds)} 초`;
};

/**
 * 메모리 제한 표기
 * @param {number} memory_limit_in_mib 메모리 제한
 * @returns {string} 메모리 제한 문자열
 */
export const memoryLimitString = function (memoryLimitInMib: number): string {
    return `${String(memoryLimitInMib)} MiB`;
};

/**
 * 실행 시간 표기
 * @param usedTimeInSeconds 실행 시간
 * @returns {string} 실행 시간 문자열
 */
export const usedTimeString = function (usedTimeInSeconds: number): string {
    return `${String(usedTimeInSeconds)} 초`;
}

/**
 * 메모리 사용량 표기
 * @param {number} usedMemoryInKib 메모리 사용량
 * @returns {string} 메모리 사용량 문자열
 */
export const usedMemoryString = function (usedMemoryInKib: number): string {
    return `${String(usedMemoryInKib)} KiB`;
};

/**
 * 시각 표기
 * @param {string} date_time 시각
 * @returns {string} 시각 문자열
 */
export const dateTimeString = function (dateTime: string): string {
    return (new Date(dateTime + 'Z')).toLocaleString("ko-kr");
}

/**
 * 부분 문제 표기
 * @param {number} subtaskOrder 부분 문제 번호
 * @returns {string} 부분 문제 문자열
 */
export const subtaskString = function (subtaskOrder: number): string {
    return `부분문제 ${subtaskOrder}`;
}

/**
 * 점수 및 최고 점수 표기
 * @param {number} score 점수
 * @param {number} fullScore 최고 점수
 * @returns {string} 점수 및 최고 점수 문자열
 */
export const scoreWithFullScoreString = function (score: number, fullScore: number): string {
    return `${Number(score.toFixed(2))} / ${fullScore}`;
}

/**
 * 현재 채점 데이터 정보 표기
 * @param {number} evaluatingSubtaskOrder 부분 문제 번호
 * @param {number} evaluatingTest 데이터 번호
 * @returns {string} 현재 채점 데이터 정보 문자열
 */
export const evaluatingTestString = function (evaluatingSubtaskOrder: number, evaluatingTest: number): string {
    return `#${evaluatingSubtaskOrder}-${evaluatingTest} 채점 중`;
}

/**
 * 부분 문제 채점 완료 표기
 * @param {number} evaluatingSubtaskOrder 부분 문제 번호
 * @returns {string} 부분 문제 채점 완료 문자열
 */
export const evaluatingSubtaskString = function (evaluatingSubtaskOrder: number): string {
    return `${subtaskString(evaluatingSubtaskOrder)} 채점 완료`;
}

export const filters = {
    timeLimitString,
    memoryLimitString,
    usedTimeString,
    usedMemoryString,
    dateTimeString,
    subtaskString,
    scoreWithFullScoreString,
};
