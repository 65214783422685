




import { Component, Vue } from "vue-property-decorator";
import AceJs from "../components/AceJs"

// https://github.com/ajaxorg/ace/tree/master/lib/ace/mode
// https://github.com/ajaxorg/ace/tree/master/lib/ace/theme

declare global {
  interface Window {
    ace: AceJs.Ace;
  }
}

@Component
export default class CodeEditorView extends Vue {
  editor: AceJs.Editor | undefined = undefined;

  isChanged = false;

  private originCode = '';

  mounted() {
    this.editor = window.ace.edit(this.$el)
    this.editor.setValue('') // Set initial source code
    this.editor.setFontSize(14);
    this.editor.setShowPrintMargin(false);
    this.editor.setTheme("ace/theme/monokai");
    this.setLanguage("cpp"); // Default

    this.editor.on('change', this.onCodeChanged);
    this.editor.setOptions({
      minLines: 15,
      maxLines: 1000,
    });
  }

  getCode(): string {
    return this.editor ? this.editor.getValue() : ''
  }

  /*
   * (C) c -> c_cpp
   * (C++) cpp -> c_cpp
   * (Java) java -> java
   * (Python) py -> python
   * (Kotlin) kt -> kotlin
   * Others -> c_cpp (default)
   */
  extension2modeSuffix(extension: string): string {
    if('c' === extension) return 'c_cpp';
    if('cpp' === extension) return 'c_cpp';
    if('java' === extension) return 'java';
    if('py' === extension) return 'python';
    if('kt' === extension) return 'kotlin';
    return 'c_cpp';
  }

  setLanguage(extension: string): void {
    this.editor?.session.setMode("ace/mode/" + this.extension2modeSuffix(extension));
  }

  setCode(code: string): void {
    this.originCode = code;
    this.editor?.setValue(code);
    this.editor?.clearSelection();
  }

  setReadOnly(readOnly: boolean): void {
    this.editor?.setReadOnly(readOnly);
  }

  setAutoHeight(): void {
    (this.editor as AceJs.Editor).setOptions({
      maxLines: 1000,
    });
  }

  onCodeChanged(): void {
    this.isChanged = this.getCode() !== this.originCode;
  }
}
