











import { Vue, Prop, Component } from "vue-property-decorator";
import SubmissionsList from "@/components/SubmissionsList.vue";
import * as api from "../api/gen";

interface Params {
  cursor?: number;
  problemId?: number;
  perPage?: number;
  //options?: any;
}

@Component({
  components: {
    SubmissionsList,
  },
  metaInfo() {
    return {
      title: '제출 목록',
    };
  },
})
export default class ProblemSubmissions extends Vue {
  @Prop() problem!: api.Problem;
  @Prop() params!: Params;
}
