import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Problem from "../views/Problem.vue";
import ProblemView from "../views/ProblemView.vue";
import ProblemSubmit from "../views/ProblemSubmit.vue";
import ProblemSolution from "../views/ProblemSolution.vue";
import ProblemSubmissions from "../views/ProblemSubmissions.vue";
import Problems from "../views/Problems.vue";
import NotFound from "../views/NotFound.vue";
import Login from "../views/Login.vue";
import Submission from "../views/Submission.vue";
import Submissions from "../views/Submissions.vue";
import TermsOfService from "../views/TermsOfService.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import License from "../views/License.vue";

Vue.use(VueRouter);


const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/problems",
    component: Problems,
  },
  {
    path: "/problem/:id",
    redirect: "/problem/:id/view",
    component: Problem,
    children: [
      {
        name: "problemView",
        path: "view",
        component: ProblemView,
      },
      {
        name: "problemSubmit",
        path: "submit",
        component: ProblemSubmit,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "problemSolution",
        path: "solution",
        component: ProblemSolution,
      },
      {
        name: "problemSubmissions",
        path: "submissions",
        component: ProblemSubmissions,
        props: (route) => (
          {
            params: {
              cursor: route.query.cursor,
              perPage: route.query.perPage,
            },
          }
        ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: "submission",
    path: "/submission/:id",
    component: Submission,
  },
  {
    name: "submissions",
    path: "/submissions",
    component: Submissions,
    props: (route) => (
      {
        params: {
          cursor: route.query.cursor,
          problemId: route.query.problemId,
          perPage: route.query.perPage,
        },
      }
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: "terms",
    path: "/terms",
    component: TermsOfService,
  },
  {
    name: "privacy",
    path: "/privacy",
    component: PrivacyPolicy,
  },
  {
    name: "license",
    path: "/license",
    component: License,
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => (record.meta.requiresAuth));
  
  if(requiresAuth) {
    if(!store.getters.isAuth) {
      next({
        name: 'login',
        query: {
          next: to.fullPath,
        },
      });
    }
  }

  next();
})

store.watch((state, getters) => (getters.isAuth), function (isAuth: boolean): void {
  if(!isAuth) {
    const requiresAuth = router.currentRoute.matched.some((record) => (record.meta.requiresAuth));
    if(requiresAuth) {
      router.push({
        name: 'login',
        query: {
          next: router.currentRoute.fullPath,
        },
      })
    }
  }
});

export default router;
