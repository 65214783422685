




import { Vue, Component, Prop } from "vue-property-decorator";

import SubmissionsList from "@/components/SubmissionsList.vue";

interface Params {
  cursor?: string;
  problemId?: string;
  perPage?: string;
}

@Component({
  components: {
    SubmissionsList,
  },
  metaInfo() {
    return {
      title: '제출 목록',
    };
  },
})
export default class Submissions extends Vue {
  @Prop() params?: Params;
}
