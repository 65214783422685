






























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import CodeEditorView from "../components/CodeEditorView.vue"

import * as api from "../api/gen";
import apiConfig from "../api/config";

interface Option {
  text: string;
  value: number;
}

interface EditorData {
  editor: CodeEditorView;
  name: string;
}

@Component<ProblemSubmit>({
  components: {
    CodeEditorView,
  },
  beforeRouteLeave: function (to, from, next) {
    if(!this.loaded) {
      next();
      return;
    }

    if(this.closeWarningNeeded
      && this.isCodeWritten()) {
        if(!window.confirm('사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수도 있습니다.')) {
          return;
        }
    }

    next();
  },
  metaInfo() {
    return {
      title: '제출',
    };
  },
})
export default class ProblemSubmit extends Vue {
  @Prop() problem!: api.Problem;

  private id = -1;
  private submittableLanguages: Array<api.CodeLanguage> = [];
  private submitFiles: Array<api.SubmitFileSummary> = [];
  private fileNames: Array<string> = [];

  private options: Array<Option> = [];
  private langSelected = 0;
  private codeSelected = 0;

  private editors: Array<EditorData> = [];

  private loaded = false;
  private defaultLangLoaded = false;
  private failed = false;

  private submitErrorMessage = '';
  private submitErrorFlag = false;

  private closeWarningNeeded = true;

  private submitDisabled = true;

  get allLoaded(): boolean {
    return this.loaded && this.defaultLangLoaded;
  }

  created(): void {
    if(!this.problem) {
      this.failed = true;
      return;
    }
    
    this.id = this.problem.id || -1;
    this.submittableLanguages = this.problem.submittable_languages || [];
    this.submitFiles = this.problem.submit_files || [];

    this.options = this.submittableLanguages.map((obj, index): Option => ({
      text: obj.name + ' / ' + obj.compiler_version,
      value: index,
    }))

    this.fileNames = this.submitFiles.map((obj) => obj.name);

    window.addEventListener('beforeunload', this.onClose);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.onClose);
    });

    this.loaded = true;

    const generalApi = new api.GeneralApi(apiConfig);
    generalApi.getRecentLanguageResource()
    .then((response) => {
      const data: api.RecentLanguageSummary = response.data;
      const recentLangId: number = data.language_id;

      for(const [index, language] of this.submittableLanguages.entries()) {
        if(recentLangId === language.id) {
          this.langSelected = index;
          break;
        }
      }

      this.defaultLangLoaded = true;
    })
    .catch(() => {
      this.defaultLangLoaded = true;
    });
  }

  mounted(): void {
    if(!this.loaded) {
      return;
    }

    for(const name of this.fileNames) {
      const els = this.$refs['editor_' + name] as Array<Vue>;
      if(1 !== els.length) {
        this.loaded = false;
        this.failed = true;
        return;
      }

      const editor = els[0] as CodeEditorView;
      editor.setLanguage(this.extension);

      this.editors.push({
        editor: editor,
        name: name,
      })
    }

    this.submitDisabled = false;
  }

  get extension(): string {
    if(!this.loaded || this.failed) {
      return ''
    }

    return this.submittableLanguages[this.langSelected].extension;
  }

  @Watch('langSelected')
  onLangSelectedChanged(): void {
    if(!this.loaded) {
      return;
    }

    for(const editor of this.editors) {
      editor.editor.setLanguage(this.extension);
    }
  }

  submitCode(): void {
    if(!this.loaded) {
      return;
    }

    this.submitDisabled = true;

    const files: api.SubmitFiles = {
      language: this.submittableLanguages[this.langSelected].id,
      files: [],
    }

    for(const [index, editor] of this.editors.entries()) {
      const code = editor.editor.getCode();

      const sizeLimit = this.submitFiles[index].size_limit;
      if(sizeLimit < code.length) {
        this.submitErrorMessage = `소스 코드가 너무 깁니다. ${editor.name}.${this.extension}의 크기는 ${sizeLimit} 바이트 이하여야 합니다.`;
        this.submitErrorFlag = true;

        this.submitDisabled = false;
        return;
      }

      const file: api.SubmitFile = {
        name: editor.name,
        content: code,
      };

      files.files.push(file);
    }

    const problemApi = new api.ProblemApi(apiConfig);
    problemApi.postProblemSubmitView(this.id, files)
    .then((response) => {
      const submitResponse: api.ProblemSubmitResponse = response.data;

      this.closeWarningNeeded = false;

      this.$router.push({
        name: 'submission',
        params: {
          id: (submitResponse.id).toString(),
        },
      })
    })
    .catch((reason) => {
      this.submitDisabled = false;

      if(reason.response && typeof reason.response.status == 'number') {
        const errorCode: number = reason.response.status;

        if(429 === errorCode) {
          this.submitErrorMessage = `너무 자주 제출했습니다.`;
          this.submitErrorFlag = true;

          return;
        }
      }

      this.$store.commit('showErrorSnackbar', reason);
    })
  }

  isCodeWritten(): boolean {
    if(!this.loaded) {
      return false;
    }

    return this.editors.some((editor) => (editor.editor.isChanged));
  }

  onClose(event: Event): unknown {
    if(!this.closeWarningNeeded || !this.isCodeWritten()) {
      // No warning
      return;
    }

    event.preventDefault();

    // For Chrome Browser
    event.returnValue = true;

    // Setting user-defined message is banned
    // Return value is needed for IE
    return '';
  }
}
