






import { Auth } from "aws-amplify";
import { Vue, Component } from "vue-property-decorator";

import { onAuthUIStateChange } from "@aws-amplify/ui-components";

import Authenticator from "../components/Authenticator.vue";

@Component({
  components: {
    Authenticator,
  },
  metaInfo() {
    return {
      title: '로그인',
    };
  },
})
export default class Login extends Vue {
  goToNext() {
    const path = (this.$route.query.next as string) || '/'
    this.$router.replace({
      path,
    });
  }

  async created() {
    onAuthUIStateChange((authState, authData) => {
      if (this.$router.currentRoute.name === "login" && authState === "signedin") {
        this.$store.state.user = authData;
        this.goToNext();
      }
    });

    try {
      const user = await Auth.currentSession();
      if (user) {
        this.goToNext();
      }
    } catch (e) {
      return;
    }
  }

  beforeDestroy() {
    return onAuthUIStateChange;
  }

}
