

















































































import { Vue, Prop, Component } from "vue-property-decorator";
import * as api from "../api/gen";
import apiConfig from "../api/config";
import CodeEditorView from "../components/CodeEditorView.vue";
import SubmissionsTable from "@/components/SubmissionsTable.vue";
import SubmissionTestResultVerdictView from "@/components/SubmissionTestResultVerdictView.vue";

import * as Pusher from "../lib/pusher";

interface EditorData {
  editor: CodeEditorView;
  name: string;
}

interface SubtaskHeader {
  text: string;
  value: string;
}

type SubtasksHeader = Array<SubtaskHeader>;

@Component<SubmissionView>({
  components: {
    CodeEditorView,
    SubmissionsTable,
    SubmissionTestResultVerdictView,
  },
  beforeDestroy() {
    if(this.pusher) {
      this.pusher.disconnect();
    }
  },
})
export default class SubmissionView extends Vue {
  @Prop({ required: true }) readonly initSubmission!: api.ProblemSubmission;
  private submission: api.ProblemSubmission = this.initSubmission;

  private pusher: Pusher.Pusher | undefined = undefined;

  private editors: Array<EditorData> = [];

  private fileNames: Array<string> = [];
  private codes: Array<string> = [];
  private extension = '';

  private codeSelected = 0;

  private loaded = false;
  private failed = false;


  private compilationMessagePanels = [0];


  private subtasksHeader: SubtasksHeader = [
    {
      text: "부분문제",
      value: "id",
    },
    {
      text: "채점 결과",
      value: "verdict",
    },
    {
      text: "점수",
      value: "score",
    },
  ];


  created(): void {
    if(!this.submission) {
      this.failed = true;
      return;
    }

    const submitFiles: Array<api.SubmitFile> = this.submission.submitted_files || [];

    this.fileNames = submitFiles.map((obj) => (obj.name));
    this.codes = submitFiles.map((obj) => (obj.content));

    this.extension = this.submission.language?.extension || '';

    this.loaded = true;
  }

  mounted() {
    if(!this.loaded) {
      return;
    }

    Pusher.getPusher(apiConfig)
    .then((pusher) => {
      this.pusher = pusher;

      if(!this.pusher) {
        return;
      }

      Pusher.updateSubmissionWithPusher(apiConfig, this.submission, this.pusher, undefined, () => {
        this.$store.commit('showErrorSnackbar', "제출 상황 실시간 업데이트에 실패했습니다.");
      });
    })
    .catch(() => {
      this.$store.commit('showErrorSnackbar', "제출 상황 실시간 업데이트에 실패했습니다.");
    });

    for(const [index, name] of this.fileNames.entries()) {
      const els = this.$refs['editor_' + name] as Array<Vue>;
      if(1 !== els.length) {
        continue;
      }

      const editor = els[0] as CodeEditorView;
      editor.setLanguage(this.extension);
      editor.setCode(this.codes[index]);
      editor.setReadOnly(true);

      this.editors.push({
        editor: editor,
        name: name,
      });
    }
  }

  private get subtaskResults(): Array<api.SubmissionSubtaskResultSummary> {
    return this.submission.subtask_results;
  }
}
