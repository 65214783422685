import { Auth } from "@aws-amplify/auth";
import * as api from "./gen";

export default new api.Configuration({
  basePath: process.env.VUE_APP_API_SERVER_URL,
  apiKey: async () => {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
      return "Bearer " + accessToken.getJwtToken();
    // eslint-disable-next-line no-empty
    } catch(e) {
      return "";
    }
  },
});
