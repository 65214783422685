






import { Vue, Component } from "vue-property-decorator";

import marked from "marked";
import text from "!!raw-loader!../../public/TermsOfService.md";

@Component({
  metaInfo() {
    return {
      title: '이용 약관',
    };
  },
})
export default class TermsOfService extends Vue {
  get compiledMd() {
    return marked(text, { breaks: true });
  }
}
