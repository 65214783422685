








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";

@Component({
  metaInfo() {
    return {
      title: '라이센스',
    };
  },
})
export default class License extends Vue {}
