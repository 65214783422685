





















































import { Vue, Prop, Component } from "vue-property-decorator";

import ScoreBar from "@/components/ScoreBar.vue";

import * as api from "../api/gen";

import * as Pusher from "../lib/pusher";

@Component({
  components: {
    ScoreBar,
  },
})
export default class SubmissionsTable extends Vue {
  @Prop({ required: true }) readonly submissionsList!: Array<api.ProblemSubmissionSummary | api.ProblemSubmission>;

  private pusher: Pusher.Pusher | undefined = undefined;

  get listItems() {
    return this.submissionsList;
  }

  noSubmissionMessage = "제출이 없습니다.";

  nextCursor = 0;
  submissionsHeader = [
    { text: "제출 번호", value: "id", width: 80 },
    { text: "제출 시각", value: "time", width: 220 },
    { text: "문제", value: "course_problem", width: 200 },
    { text: "제출 언어", value: "language", width: 120 },
    { text: "채점 상황", value: "state_message", width: 200 },
    { text: "채점 결과", value: "score", width: 200 },
    { text: "실행 시간", value: "execution_time", width: 100},
    { text: "메모리", value: "memory", width: 120 },
  ];
}
