









































































































































































import Vue from "vue";
import Component from "vue-class-component";

import { Auth } from "@aws-amplify/auth";

import Authenticator from "./components/Authenticator.vue";

import VueMeta from "vue-meta";

Vue.use(VueMeta);

@Component({
  components: {
    Authenticator,
  },
  metaInfo() {
    const titleTemplate = this.$store.state.defaultTitleTemplate;

    return {
      titleTemplate: titleTemplate,
    };
  },
})
export default class App extends Vue {
  user: object | null = null;
  drawer = false;

  NODE_ENV = process.env.NODE_ENV;

  menu_items = [
    {
      label: "홈",
      routerLink: "/",
    },
    {
      label: "문제",
      routerLink: "/problems",
    },
    {
      label: "제출 목록",
      routerLink: "/submissions?default=1",
    },
  ];

  activeMenu: string | null = null;

  get isLoggedIn() {
    return this.$store.getters.isAuth;
  }

  async signOut() {
    await Auth.signOut();
    this.$store.state.user = undefined;
    this.$forceUpdate();
  }
}
