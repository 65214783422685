


















import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class ScoreBar extends Vue {
  @Prop() user_score!: number;
  @Prop() accepted_score!: number;
}
