import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import { I18n } from "@aws-amplify/core";
import { koreanVocabularyDict } from "./translations";
import vuetify from "./plugins/vuetify";

import VueGtag from "vue-gtag";

import * as utils from "./utils";

I18n.setLanguage("ko");
I18n.putVocabulariesForLanguage("ko", koreanVocabularyDict);

Amplify.configure(awsconfig);

for(const [name, definition] of Object.entries(utils.filters)) {
  Vue.filter(name, definition);
}

if (process.env.VUE_APP_GTAG_ID) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID },
  }, router);  
}

Vue.config.productionTip = false;

store.dispatch("loadAuth").then(() => {
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => (record.meta.requiresAuth));
    
    if(requiresAuth) {
      if(!store.getters.isAuth) {
        next({
          name: 'login',
          query: {
            next: to.fullPath,
          },
        });
      }
    }
  
    next();
  })

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
})
