



















































import { Vue, Prop, Component } from "vue-property-decorator";

import ScoreBar from "@/components/ScoreBar.vue";
import PDFView from "@/components/PDFView.vue";

import * as api from "../api/gen";
// import apiConfig from "../api/config";

@Component({
  metaInfo() {
    return {
      title: '문제 보기',
    };
  },
  components: {
    ScoreBar,
    PDFView,
  },
})
export default class ProblemView extends Vue {
  @Prop() problem!: api.Problem;
}
