import { Translations } from "@aws-amplify/ui-components";

export const koreanVocabularyDict = {
  [Translations.CHANGE_PASSWORD_ACTION]: "비밀번호 변경",
  [Translations.CHANGE_PASSWORD]: "비밀번호 변경",
  [Translations.USERNAME_LABEL]: "아이디 *",
  [Translations.USERNAME_PLACEHOLDER]: "아이디를 입력하세요",
  [Translations.PASSWORD_LABEL]: "비밀번호 *",
  [Translations.PASSWORD_PLACEHOLDER]: "비밀번호를 입력하세요",
  [Translations.FORGOT_PASSWORD_TEXT]: "비밀번호를 잊으셨나요?",
  [Translations.RESET_PASSWORD_TEXT]: "비밀번호 찾기",
  [Translations.RESET_YOUR_PASSWORD]: "비밀번호를 초기화하세요.",
  [Translations.NO_ACCOUNT_TEXT]: "계정이 없나요?",
  [Translations.CREATE_ACCOUNT_TEXT]: "회원가입",
  [Translations.SIGN_IN_TEXT]: "로그인",
  [Translations.SIGN_IN_ACTION]: "로그인",
  [Translations.EMAIL_LABEL]: "이메일 주소 *",
  [Translations.EMAIL_PLACEHOLDER]: "이메일 주소를 입력하세요",
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "이메일 주소를 입력하세요",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "이미 계정이 있나요?",
  [Translations.SIGN_UP_HEADER_TEXT]: "회원가입",
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "비밀번호를 입력하세요",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "회원가입",
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "아이디를 입력하세요",
  [Translations.CODE_LABEL]: "인증 코드",
  [Translations.CODE_PLACEHOLDER]: "인증 코드를 입력하세요",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "인증 코드",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "인증 코드를 입력하세요",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "인증 코드 확인",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]:
    "이메일 주소로 인증 코드가 전송되지 않았나요?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "인증 코드 다시 보내기",
  [Translations.SUBMIT]: "제출",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "확인",
  [Translations.DEFAULT_MSG]: "로그인 중에 오류가 발생했습니다.",
  [Translations.EMPTY_USERNAME]: "아이디가 비어 있습니다.",
  [Translations.INVALID_USERNAME]: "아이디가 올바르지 않은 형식입니다.",
  [Translations.EMPTY_PASSWORD]: "비밀번호가 비어 있습니다.",
  [Translations.EMPTY_CODE]: "확인 코드가 비어 있습니다.",
  [Translations.SIGN_UP_ERROR]: "회원가입 중에 오류가 발생했습니다.",
  [Translations.NO_USER_SESSION]: "세션을 가져오는 데에 실패했습니다.",
  [Translations.BACK_TO_SIGN_IN]: "로그인 페이지로 돌아가기",
  [Translations.SIGN_OUT]: "로그아웃",
  [Translations.NEW_PASSWORD_LABEL]: "새 비밀번호",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "새 비밀번호를 입력하세요",
  [Translations.SEND_CODE]: "이메일로 인증 코드 보내기",
  "Password attempts exceeded":
    "로그인 시도가 너무 많아 로그인이 차단되었습니다. 비밀번호 찾기를 통해 비밀번호를 초기화해 주세요.",
  "Incorrect username or password.": "아이디 또는 비밀번호가 틀렸습니다.",
  "User does not exist.": "존재하지 않는 사용자입니다.",
  "Invalid email address format.": "이메일 주소 형식이 올바르지 않습니다.",
  "Attribute value for email must not be null": "이메일 주소가 비어 있습니다.",
  "Password did not conform to policy: Password must have uppercase characters":
    "비밀번호는 알파벳 대문자(A-Z)를 포함해야 합니다.",
  "Password did not conform to policy: Password must have lowercase characters":
    "비밀번호는 알파벳 소문자(a-z)를 포함해야 합니다.",
  "Password did not conform to policy: Password must have numeric characters":
    "비밀번호는 숫자(0-9)를 포함해야 합니다.",
  "Password did not conform to policy: Password not long enough":
    "비밀번호가 너무 짧습니다.",
  "Password did not conform with policy: Password must have uppercase characters":
    "비밀번호는 알파벳 대문자(A-Z)를 포함해야 합니다.",
  "Password did not conform with policy: Password must have lowercase characters":
    "비밀번호는 알파벳 소문자(a-z)를 포함해야 합니다.",
  "Password did not conform with policy: Password must have numeric characters":
    "비밀번호는 숫자(0-9)를 포함해야 합니다.",
  "Password did not conform with policy: Password not long enough":
    "비밀번호가 너무 짧습니다.",
  "Password does not conform to policy: Password must have uppercase characters":
    "비밀번호는 알파벳 대문자(A-Z)를 포함해야 합니다.",
  "Password does not conform to policy: Password must have lowercase characters":
    "비밀번호는 알파벳 소문자(a-z)를 포함해야 합니다.",
  "Password does not conform to policy: Password must have numeric characters":
    "비밀번호는 숫자(0-9)를 포함해야 합니다.",
  "Password does not conform to policy: Password not long enough":
    "비밀번호가 너무 짧습니다.",
  "Password does not conform with policy: Password must have uppercase characters":
    "비밀번호는 알파벳 대문자(A-Z)를 포함해야 합니다.",
  "Password does not conform with policy: Password must have lowercase characters":
    "비밀번호는 알파벳 소문자(a-z)를 포함해야 합니다.",
  "Password does not conform with policy: Password must have numeric characters":
    "비밀번호는 숫자(0-9)를 포함해야 합니다.",
  "Password does not conform with policy: Password not long enough":
    "비밀번호가 너무 짧습니다.",
  "An account with the given email already exists.":
    "해당 이메일 주소는 이미 가입되어 있습니다.",
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
    "비밀번호는 6글자 이상이어야 합니다.",
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
    "비밀번호는 6글자 이상이어야 하고, 공백 문자로 시작하거나 끝날 수 없습니다.",
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$; Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
    "비밀번호는 6글자 이상이어야 하고, 공백 문자로 시작하거나 끝날 수 없습니다.",
  "Username/client id combination not found.":
    "입력하신 정보에 맞는 계정이 없습니다.",
  "PreSignUp failed with error A user with the same email address exists.":
    "해당 이메일 주소로 가입한 계정이 있습니다.",
  "PreSignUp failed with error Should agree on terms..":
    "개인정보취급방침 및 약관을 읽고 동의하셔야 합니다.",
  "Attribute value for custom:doyouagree must not be null":
    "개인정보취급방침 및 약관을 읽고 동의하셔야 합니다.",
  "User already exists": "같은 아이디를 사용하는 계정이 이미 있습니다.",
  "Invalid verification code provided, please try again.":
    "인증 코드가 잘못되었습니다. 확인 후 다시 입력해 주세요.",
};
