









































import { Vue, Component } from "vue-property-decorator";

import ScoreBar from "@/components/ScoreBar.vue";

import * as api from "../api/gen";
import apiConfig from "../api/config";

@Component({
  components: {
    ScoreBar,
  },
  metaInfo() {
    return {
      title: '문제',
    };
  },
})
export default class Problems extends Vue {
  loading = false;
  groups: api.CourseProblemGroup[] = [];

  get problemGroupHeaders() {
    const headers: object[] = [];
    headers.push({
      text: "제목",
      value: "title",
    });
    if (this.$store.state.user) {
      headers.push({
        text: "나의 점수",
        value: "user_score",
        width: "300px",
      });
    }
    return headers;
  }

  created() {
    this.loading = true;

    const groupsApi = new api.GroupApi(apiConfig);
    groupsApi.getCourseProblemGroupList()
      .then(response => {
        if (response.data.groups) {
          this.loading = false;
          this.groups = response.data.groups;
        }
      })
      .catch(reason => {
        this.loading = false;
        this.$store.commit('showErrorSnackbar', reason);
      });
  }
}
