
















































import { Vue, Prop, Component, Watch, Ref } from "vue-property-decorator";

import PDFView from "@/components/PDFView.vue";

import * as api from "../api/gen";
// import apiConfig from "../api/config";

@Component({
  components: {
    PDFView,
  },
  metaInfo() {
    return {
      title: '풀이',
    };
  },
})
export default class ProblemSolution extends Vue {
  @Prop() problem!: api.Problem;

  @Ref() readonly solutionYoutube!: HTMLIFrameElement;

  // 0이면 열린 상태, 0이 아니면 닫힌 상태
  pdfPanel?: number = 1;
  videoPanel?: number = 1;

  // expansion panel을 닫으면 동영상 일시정지
  @Watch('videoPanel')
  panelClosed(v?: number) {
    if (v !== 0 && this.solutionYoutube.contentWindow != null) {
      this.solutionYoutube
        .contentWindow
        .postMessage(`{
          "event": "command",
          "func": "pauseVideo",
          "args": ""
        }`, '*');
    }
  }

  // 화면 크기가 lg 미만으로 줄어들 경우 두 expansion panel을 모두 닫는다.
  @Watch('$vuetify.breakpoint.lgAndUp')
  onResize(v: boolean) {
    if(!v) {
      this.pdfPanel = 1;
      this.videoPanel = 1;
    }
  }

  mounted() {
    // 화면 크기가 lg 이상이면 두 expansion panel을 모두 연다.
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.pdfPanel = 0;
      this.videoPanel = 0;
    }
  }
}
