
































































import { Vue, Component } from "vue-property-decorator";

interface LogoItem{
  year: number;
  url: string;
}

@Component({
  metaInfo() {
    return {
      title: '홈',
    };
  },
})
export default class Home extends Vue {
  logoList: Array<LogoItem> = [];
  infoList: Array<string> = [
    "역대 IOI와 KOI 기출문제를 열람할 수 있습니다.",
    "실제 대회 환경에서처럼 코드를 제출하고, 결과를 확인할 수 있습니다.",
    "PDF와 동영상 풀이를 제공합니다.",
  ];
  
  created() {
    for(let year = 2020; year >= 2010; year -- ) {
      this.logoList.push({
        year: year,
        url: `/imgs/logos/${year}.png`,
      });
    }
  }
}
