import * as api from "../api/gen";
import * as utils from "../utils";


export enum EventType {
    SubscriptionSucceeded = 'pusher:subscription_succeeded',
    TestStarted = 'test-started',
    SubtaskCompleted = 'subtask-completed',
    JudgeCompleted = 'judge-completed',
}


interface TimeData {
    time: number;
}

interface EvaluatingData extends TimeData {
    evaluating_subtask_order: number;
    max_memory: number;
    max_execution_time_in_seconds: number;
}


export type TestStartedData = EvaluatingData & {
    evaluating_test: number;
}

export type SubtaskCompletedData = EvaluatingData & {
    rate_by_percent: number;
    score: number;
}

export type JudgeCompletedData = TimeData;



export interface Channel {
    bind(eventName: EventType.SubscriptionSucceeded, callback: () => void): void;
    bind(eventName: EventType.TestStarted, callback: (data: TestStartedData) => void): void;
    bind(eventName: EventType.SubtaskCompleted, callback: (data: SubtaskCompletedData) => void): void;
    bind(eventName: EventType.JudgeCompleted, callback: (data: JudgeCompletedData) => void): void;
}

interface PusherConnection {
    state?: string;
}

export interface Pusher {
    subscribe(channelName: string): Channel;
    disconnect(): void;

    connection?: PusherConnection;
}

interface PusherConstructor {
    new (appKey: string, options?: Record<string, unknown>): Pusher;
}

declare global {
    interface Window {
        Pusher: PusherConstructor;
    }
}


// Pusher APP_KEY
const appKey = process.env.VUE_APP_PUSHER_APP_KEY || '';

// Pusher Destination of the Authentication Request
const authEndpoint = (new URL("/general/pusher/auth", process.env.VUE_APP_API_SERVER_URL)).href;


/**
 * api key getter
 * @param {api.Configuration} configuration
 * @returns {string} authorization value
 */
const getApiKey = async function (configuration: api.Configuration): Promise<string> {
    if(configuration && configuration.apiKey) {
        return typeof configuration.apiKey === 'function'
            ? await configuration.apiKey("Authorization")
            : await configuration.apiKey;
    }

    return '';
}

/**
 * get new Pusher
 * @param {api.Configuration} configuration 
 * @returns {Pusher} pusher
 */
export const getPusher = async function (configuration: api.Configuration): Promise<Pusher | undefined> {
    try {
        return new window.Pusher(appKey, {
            cluster: 'ap1',
            encrypted: true,
            authEndpoint: authEndpoint,
            auth: {
                headers: {
                    'Authorization': await getApiKey(configuration),
                },
            },
        });
    }
    catch {
        return undefined;
    }
}

/**
 * get channel from pusher
 * @param {Pusher} pusher pusher
 * @param {string} channelName channel name
 * @returns {Channel} channel
 */
const getChannelFromPusher = async function (pusher: Pusher, channelName: string): Promise<Channel> {
    return pusher.subscribe(channelName)
}

/**
 * get channel
 * @param {api.Configuration} configuration configuration
 * @param {string} channelName channel name
 * @returns {Channel} channel
 */
const getChannel = async function (configuration: api.Configuration, channelName: string): Promise<Channel | undefined> {
    const pusher = await getPusher(configuration);
    return pusher
        ? getChannelFromPusher(pusher, channelName)
        : undefined;
}


/**
 * get submission channel
 * @param {api.Configuration} configuration configuration
 * @param {api.ProblemSubmission | api.ProblemSubmissionSummary} submission submission
 * @returns {Channel} channel
 */
export const getSubmissionChannel = async function (configuration: api.Configuration, submission: api.ProblemSubmission | api.ProblemSubmissionSummary): Promise<Channel | undefined> {
    return getChannel(configuration, submission.pusher_channel_name);
}


/* eslint-disable @typescript-eslint/camelcase */
export const updateSubmission = async function (configuration: api.Configuration,
                                                submission: api.ProblemSubmission | api.ProblemSubmissionSummary,
                                                channel?: Channel,
                                                updateCallback?: (newVal: api.ProblemSubmission | api.ProblemSubmissionSummary) => void,
                                                errorCallback?: (reason: string) => void) {
    channel = channel
            ? channel
            : await getSubmissionChannel(configuration, submission);
    
    if(!channel) {
        if(errorCallback) {
            errorCallback('Loading channel failed');
        }
        return;
    }

    let channelSubscribed = false;
    let channelLastTime = -1;

    let isUpdating = false;
    let stopUpdate = false;

    /**
     * subtask 정보를 업데이트
     * @param {number} lastSubtaskNumber 채점이 완료된 last subtask의 번호
     */
    const updateSubtaskScores = (lastSubtaskNumber: number): void => {
        /**
         * 현재 다른 updateSubtaskScores 함수가 작동 중이지 않고
         * 채점이 진행중이며
         * submission가 subtask_results 속성을 가지고 있고
         * 새로운 subtask 채점이 완료되어 그 정보를 업데이트 해야 할 때에만
         * API를 통하여, subtask 정보를 가져온다.
         */
        if(isUpdating
            || stopUpdate
            || !("subtask_results" in submission)
            || lastSubtaskNumber <= submission.subtask_results.length) {
            return;
        }

        isUpdating = true;

        const submissionApi = new api.SubmissionApi(configuration);
        submissionApi.getProblemSubmissionView(submission.id)
        .then((response) => {
            if(!stopUpdate) {
                const finalSubmission = response.data as api.ProblemSubmission;
                submission.subtask_results = finalSubmission.subtask_results;

                if(updateCallback) {
                    updateCallback(submission);
                }
            }

            isUpdating = false;
        })
        .catch(() => {
            isUpdating = false;
        });
    };


    channel.bind(EventType.SubscriptionSucceeded, () => {
        channelSubscribed = true;
    })

    channel.bind(EventType.TestStarted, (data) => {
        if(channelSubscribed) {
            if(data.time < channelLastTime) {
                return;
            }

            channelLastTime = data.time;

            submission.state_message = utils.evaluatingTestString(data.evaluating_subtask_order, data.evaluating_test);
            submission.max_execution_time_in_seconds = Math.max(submission.max_execution_time_in_seconds, data.max_execution_time_in_seconds);
            submission.max_used_memory_in_kb = Math.max(submission.max_used_memory_in_kb, data.max_memory);

            if(updateCallback) {
                updateCallback(submission);
            }

            /**
             * 현재 data.evaluation_subtask_order번째 subtask를 채점 중이므로,
             * 현재까지 채점이 완료된 last subtask의 번호는 이보다 1 작다.
             */
            updateSubtaskScores(data.evaluating_subtask_order - 1);
        }
    });

    channel.bind(EventType.SubtaskCompleted, (data) => {
        if(channelSubscribed) {
            if(data.time < channelLastTime) {
                return;
            }

            channelLastTime = data.time;

            submission.state_message = utils.evaluatingSubtaskString(data.evaluating_subtask_order);
            submission.max_execution_time_in_seconds = Math.max(submission.max_execution_time_in_seconds, data.max_execution_time_in_seconds);
            submission.max_used_memory_in_kb = Math.max(submission.max_used_memory_in_kb, data.max_memory);
            submission.score = data.score;

            if(updateCallback) {
                updateCallback(submission);
            }

            updateSubtaskScores(data.evaluating_subtask_order);
      }
    });

    channel.bind(EventType.JudgeCompleted, (data) => {
        if(channelSubscribed) {
            if(data.time < channelLastTime) {
                return;
            }

            channelLastTime = data.time;

            submission.state_message = `채점 완료`;

            if(updateCallback) {
                updateCallback(submission);
            }

            /**
             * 채점이 완료되었으므로,
             * updateSubtaskScores의 작동을 중지한다.
             */
            stopUpdate = true;

            const submissionApi = new api.SubmissionApi(configuration);
            submissionApi.getProblemSubmissionView(submission.id)
            .then((response) => {
                const finalSubmission = response.data as api.ProblemSubmission;

                submission.accepted_score = finalSubmission.accepted_score;
                if ("compilation_message" in submission) {
                    submission.compilation_message = finalSubmission.compilation_message;
                }
                submission.max_execution_time_in_seconds = finalSubmission.max_execution_time_in_seconds;
                submission.max_used_memory_in_kb = finalSubmission.max_used_memory_in_kb;
                submission.score = finalSubmission.score;
                submission.state_message = finalSubmission.state_message;

                if("subtask_results" in submission) {
                    submission.subtask_results = finalSubmission.subtask_results;
                }

                if(updateCallback) {
                    updateCallback(submission);
                }
            })
            .catch(() => {
                return;
            });
        }
    });
}

export const updateSubmissionWithPusher = async function (configuration: api.Configuration,
                                                          submission: api.ProblemSubmission,
                                                          pusher: Pusher,
                                                          updateCallback?: (newVal: api.ProblemSubmission | api.ProblemSubmissionSummary) => void,
                                                          errorCallback?: (reason: string) => void) {
    if(!pusher) {
        if(errorCallback) {
            errorCallback("Loading pusher failed");
        }
        return;
    }

    const channel = await getChannelFromPusher(pusher, submission.pusher_channel_name);
    if(!channel) {
        if(errorCallback) {
            errorCallback("Loading channel failed");
        }
        return;
    }

    updateSubmission(configuration, submission, channel, updateCallback, errorCallback);
}

export const updateSubmissions = async function (configuration: api.Configuration,
                                                 submissions: Array<api.ProblemSubmission | api.ProblemSubmissionSummary>,
                                                 pusher: Pusher,
                                                 updateCallback?: (index: number, newVal: api.ProblemSubmission | api.ProblemSubmissionSummary) => void,
                                                 errorCallback?: (reason: string) => void) {
    if(!pusher) {
        if(errorCallback) {
            errorCallback("Loading pusher failed");
        }
        return;
    }

    submissions.forEach(async function (submission, index) {
        const channel = await getChannelFromPusher(pusher, submission.pusher_channel_name);
        if(!channel) {
            if(errorCallback) {
                errorCallback(`Loading channel failed: indexAt ${index}`);
            }
            return;
        }

        updateSubmission(configuration, submission, channel, (newVal) => {
            if(updateCallback) {
                updateCallback(index, newVal);
            }
        }, (reason) => {
            if(errorCallback) {
                errorCallback(`Error occurred: indexAt ${index}: ${reason}`);
            }
        });
    });
}
