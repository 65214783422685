






import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class SubmissionTestResultVerdictView extends Vue {
  @Prop({ required: true }) readonly verdict!: string;
  @Prop({ required: true }) readonly score!: number;
  @Prop({ required: true }) readonly fullScore!: number;

  private get verdictStyle(): string {
    if(this.fullScore <= this.score) {
        return `color: #28B463 !important; font-weight: bold !important;`;
    }

    if(0 < this.score) {
        return `color: #D4AC0D !important;`;
    }

    return `color: #CB4335 !important;`;
  }
}
