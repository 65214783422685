/* tslint:disable */
/* eslint-disable */
/**
 * oj.uz course API
 * oj.uz course user API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CodeLanguage
 */
export interface CodeLanguage {
    /**
     * 언어 ID. 가령 답안 제출할 때 language id.
     * @type {number}
     * @memberof CodeLanguage
     */
    id: number;
    /**
     * 언어 이름
     * @type {string}
     * @memberof CodeLanguage
     */
    name: string;
    /**
     * 파일 확장자
     * @type {string}
     * @memberof CodeLanguage
     */
    extension: string;
    /**
     * 컴파일러/인터프리터 버전
     * @type {string}
     * @memberof CodeLanguage
     */
    compiler_version: string;
}
/**
 * 
 * @export
 * @interface CourseProblemGroup
 */
export interface CourseProblemGroup {
    /**
     * 문제 그룹 ID
     * @type {number}
     * @memberof CourseProblemGroup
     */
    id?: number;
    /**
     * 문제 그룹 제목
     * @type {string}
     * @memberof CourseProblemGroup
     */
    title?: string;
    /**
     * 
     * @type {Array<SummarizedCourseProblem>}
     * @memberof CourseProblemGroup
     */
    problems?: Array<SummarizedCourseProblem>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface GeneralResponse
 */
export interface GeneralResponse {
    /**
     * course ID
     * @type {number}
     * @memberof GeneralResponse
     */
    id?: number;
    /**
     * course title
     * @type {string}
     * @memberof GeneralResponse
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface GroupListResponse
 */
export interface GroupListResponse {
    /**
     * 
     * @type {Array<CourseProblemGroup>}
     * @memberof GroupListResponse
     */
    groups?: Array<CourseProblemGroup>;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * 문제 ID
     * @type {number}
     * @memberof Problem
     */
    id: number;
    /**
     * 시간 제한 (초 단위)
     * @type {number}
     * @memberof Problem
     */
    time_limit_in_seconds: number;
    /**
     * 메모리 제한 (MiB 단위)
     * @type {number}
     * @memberof Problem
     */
    memory_limit_in_mib: number;
    /**
     * 문제 제목
     * @type {string}
     * @memberof Problem
     */
    title: string;
    /**
     * 사용자가 이 문제에서 받은 점수
     * @type {number}
     * @memberof Problem
     */
    user_score: number;
    /**
     * 이 문제의 만점
     * @type {number}
     * @memberof Problem
     */
    accepted_score: number;
    /**
     * 이 문제를 해결한 사람 수
     * @type {number}
     * @memberof Problem
     */
    num_solved: number;
    /**
     * 문제 디스크립션 pdf 주소. 없을 시 빈 문자열(\"\")이 반환됨.
     * @type {string}
     * @memberof Problem
     */
    statement_pdf_url: string;
    /**
     * 문제 해설 youtube 주소. 없을 시 빈 문자열(\"\")이 반환됨.
     * @type {string}
     * @memberof Problem
     */
    solution_youtube_url: string;
    /**
     * 문제 해설 pdf 파일 주소. 없을 시 빈 문자열(\"\")이 반환됨.
     * @type {string}
     * @memberof Problem
     */
    solution_pdf_url: string;
    /**
     * 
     * @type {Array<ProblemAttachment>}
     * @memberof Problem
     */
    public_attachments: Array<ProblemAttachment>;
    /**
     * 
     * @type {Array<CodeLanguage>}
     * @memberof Problem
     */
    submittable_languages: Array<CodeLanguage>;
    /**
     * 
     * @type {Array<SubmitFileSummary>}
     * @memberof Problem
     */
    submit_files: Array<SubmitFileSummary>;
    /**
     * 제출 가능 여부. false면 제출 tab을 안 보여줘야 함
     * @type {boolean}
     * @memberof Problem
     */
    submit_enabled: boolean;
}
/**
 * 
 * @export
 * @interface ProblemAttachment
 */
export interface ProblemAttachment {
    /**
     * 파일 이름 (확장자 포함)
     * @type {number}
     * @memberof ProblemAttachment
     */
    name: number;
    /**
     * 파일 URL
     * @type {string}
     * @memberof ProblemAttachment
     */
    url: string;
    /**
     * 파일 크기 (바이트 단위)
     * @type {number}
     * @memberof ProblemAttachment
     */
    size: number;
}
/**
 * 
 * @export
 * @interface ProblemSubmission
 */
export interface ProblemSubmission {
    /**
     * 제출 번호 ID
     * @type {number}
     * @memberof ProblemSubmission
     */
    id: number;
    /**
     * 제출 시각
     * @type {string}
     * @memberof ProblemSubmission
     */
    time: string;
    /**
     * 
     * @type {SummarizedCourseProblemInSubmissionList}
     * @memberof ProblemSubmission
     */
    course_problem: SummarizedCourseProblemInSubmissionList;
    /**
     * 현재 제출 채점 상황을 표현하는 문자열 (ex: 컴파일 에러, 채점 중, 성공)
     * @type {string}
     * @memberof ProblemSubmission
     */
    state_message: string;
    /**
     * 
     * @type {CodeLanguage}
     * @memberof ProblemSubmission
     */
    language: CodeLanguage;
    /**
     * 점수
     * @type {number}
     * @memberof ProblemSubmission
     */
    score: number;
    /**
     * 이 문제의 만점
     * @type {number}
     * @memberof ProblemSubmission
     */
    accepted_score: number;
    /**
     * 최대 실행 시간, 초 단위
     * @type {number}
     * @memberof ProblemSubmission
     */
    max_execution_time_in_seconds: number;
    /**
     * 최대 사용 메모리, KB 단위
     * @type {number}
     * @memberof ProblemSubmission
     */
    max_used_memory_in_kb: number;
    /**
     * 컴파일 메시지
     * @type {string}
     * @memberof ProblemSubmission
     */
    compilation_message: string;
    /**
     * 부분문제별 결과
     * @type {Array<SubmissionSubtaskResultSummary>}
     * @memberof ProblemSubmission
     */
    subtask_results: Array<SubmissionSubtaskResultSummary>;
    /**
     * 제출한 코드의 총 길이 (바이트 단위)
     * @type {number}
     * @memberof ProblemSubmission
     */
    code_length: number;
    /**
     * 
     * @type {Array<SubmitFile>}
     * @memberof ProblemSubmission
     */
    submitted_files: Array<SubmitFile>;
    /**
     * Pusher channel name
     * @type {string}
     * @memberof ProblemSubmission
     */
    pusher_channel_name: string;
}
/**
 * 
 * @export
 * @interface ProblemSubmissionListResponse
 */
export interface ProblemSubmissionListResponse {
    /**
     * 이전 페이지 cursor. 페이지가 존재하지 않을 시 0.
     * @type {number}
     * @memberof ProblemSubmissionListResponse
     */
    prev_cursor: number;
    /**
     * 다음 페이지 cursor. 페이지가 존재하지 않을 시 0.
     * @type {number}
     * @memberof ProblemSubmissionListResponse
     */
    next_cursor: number;
    /**
     * 
     * @type {Array<ProblemSubmissionSummary>}
     * @memberof ProblemSubmissionListResponse
     */
    submissions: Array<ProblemSubmissionSummary>;
}
/**
 * 
 * @export
 * @interface ProblemSubmissionSummary
 */
export interface ProblemSubmissionSummary {
    /**
     * 제출 번호 ID
     * @type {number}
     * @memberof ProblemSubmissionSummary
     */
    id: number;
    /**
     * 제출 시각. iso8601
     * @type {string}
     * @memberof ProblemSubmissionSummary
     */
    time: string;
    /**
     * 
     * @type {SummarizedCourseProblemInSubmissionList}
     * @memberof ProblemSubmissionSummary
     */
    course_problem: SummarizedCourseProblemInSubmissionList;
    /**
     * 현재 제출 채점 상황을 표현하는 문자열 (ex: 컴파일 에러, 채점 중, 성공)
     * @type {string}
     * @memberof ProblemSubmissionSummary
     */
    state_message: string;
    /**
     * 답안 언어 이름
     * @type {string}
     * @memberof ProblemSubmissionSummary
     */
    language_name: string;
    /**
     * 점수
     * @type {number}
     * @memberof ProblemSubmissionSummary
     */
    score: number;
    /**
     * 이 문제의 만점
     * @type {number}
     * @memberof ProblemSubmissionSummary
     */
    accepted_score: number;
    /**
     * 최대 실행 시간, 초 단위
     * @type {number}
     * @memberof ProblemSubmissionSummary
     */
    max_execution_time_in_seconds: number;
    /**
     * 최대 사용 메모리, KB 단위
     * @type {number}
     * @memberof ProblemSubmissionSummary
     */
    max_used_memory_in_kb: number;
    /**
     * Pusher channel name
     * @type {string}
     * @memberof ProblemSubmissionSummary
     */
    pusher_channel_name: string;
}
/**
 * 
 * @export
 * @interface ProblemSubmitResponse
 */
export interface ProblemSubmitResponse {
    /**
     * 
     * @type {number}
     * @memberof ProblemSubmitResponse
     */
    id: number;
}
/**
 * 
 * @export
 * @interface RecentLanguageSummary
 */
export interface RecentLanguageSummary {
    /**
     * 가장 최근 제출한 언어의 ID.
     * @type {number}
     * @memberof RecentLanguageSummary
     */
    language_id: number;
}
/**
 * 
 * @export
 * @interface SubmissionSubtaskResultSummary
 */
export interface SubmissionSubtaskResultSummary {
    /**
     * 부분문제 번호
     * @type {number}
     * @memberof SubmissionSubtaskResultSummary
     */
    subtask_number: number;
    /**
     * 사용자가 받은 점수
     * @type {number}
     * @memberof SubmissionSubtaskResultSummary
     */
    score: number;
    /**
     * 부분문제 만점
     * @type {number}
     * @memberof SubmissionSubtaskResultSummary
     */
    full_score: number;
    /**
     * 채점 결과 (ex: 맞았습니다 | 틀렸습니다 | 시간 초과 | 런타임 오류 | ..)
     * @type {string}
     * @memberof SubmissionSubtaskResultSummary
     */
    verdict: string;
}
/**
 * 
 * @export
 * @interface SubmitFile
 */
export interface SubmitFile {
    /**
     * 제출할 파일 이름. 중복되면 안 되며, /problem/view/{id}의 .submit_files.name과 같아야 함
     * @type {string}
     * @memberof SubmitFile
     */
    name: string;
    /**
     * 제출할 파일에 대한 코드. 코드 크기 제한을 지켜야 함.
     * @type {string}
     * @memberof SubmitFile
     */
    content: string;
}
/**
 * 
 * @export
 * @interface SubmitFileSummary
 */
export interface SubmitFileSummary {
    /**
     * 제출할 파일 이름
     * @type {string}
     * @memberof SubmitFileSummary
     */
    name: string;
    /**
     * 제출할 파일 크기 제한 (바이트 단위)
     * @type {number}
     * @memberof SubmitFileSummary
     */
    size_limit: number;
}
/**
 * 
 * @export
 * @interface SubmitFiles
 */
export interface SubmitFiles {
    /**
     * 제출할 답안의 언어 ID
     * @type {number}
     * @memberof SubmitFiles
     */
    language: number;
    /**
     * 
     * @type {Array<SubmitFile>}
     * @memberof SubmitFiles
     */
    files: Array<SubmitFile>;
}
/**
 * 
 * @export
 * @interface SummarizedCourseProblem
 */
export interface SummarizedCourseProblem {
    /**
     * 문제 ID
     * @type {number}
     * @memberof SummarizedCourseProblem
     */
    id?: number;
    /**
     * 문제 제목
     * @type {string}
     * @memberof SummarizedCourseProblem
     */
    title?: string;
    /**
     * 해결한 사람 수
     * @type {number}
     * @memberof SummarizedCourseProblem
     */
    num_solved?: number;
    /**
     * 사용자가 이 문제에서 받은 점수
     * @type {number}
     * @memberof SummarizedCourseProblem
     */
    user_score?: number;
    /**
     * 이 문제의 만점
     * @type {number}
     * @memberof SummarizedCourseProblem
     */
    accepted_score?: number;
}
/**
 * 
 * @export
 * @interface SummarizedCourseProblemInSubmissionList
 */
export interface SummarizedCourseProblemInSubmissionList {
    /**
     * 문제 ID
     * @type {number}
     * @memberof SummarizedCourseProblemInSubmissionList
     */
    id: number;
    /**
     * 문제 제목
     * @type {string}
     * @memberof SummarizedCourseProblemInSubmissionList
     */
    title: string;
}

/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * course 관련 정보 추출
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoResource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 가장 최근 제출한 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentLanguageResource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/general/recent-language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Pusher authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInfoResource: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/general/pusher/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    return {
        /**
         * course 관련 정보 추출
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoResource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralResponse>> {
            const localVarAxiosArgs = await GeneralApiAxiosParamCreator(configuration).getInfoResource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 가장 최근 제출한 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentLanguageResource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecentLanguageSummary>> {
            const localVarAxiosArgs = await GeneralApiAxiosParamCreator(configuration).getRecentLanguageResource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Pusher authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInfoResource(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GeneralApiAxiosParamCreator(configuration).postInfoResource(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * course 관련 정보 추출
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoResource(options?: any): AxiosPromise<GeneralResponse> {
            return GeneralApiFp(configuration).getInfoResource(options).then((request) => request(axios, basePath));
        },
        /**
         * 가장 최근 제출한 언어 가져오기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentLanguageResource(options?: any): AxiosPromise<RecentLanguageSummary> {
            return GeneralApiFp(configuration).getRecentLanguageResource(options).then((request) => request(axios, basePath));
        },
        /**
         * Pusher authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInfoResource(options?: any): AxiosPromise<void> {
            return GeneralApiFp(configuration).postInfoResource(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * course 관련 정보 추출
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getInfoResource(options?: any) {
        return GeneralApiFp(this.configuration).getInfoResource(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 가장 최근 제출한 언어 가져오기
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getRecentLanguageResource(options?: any) {
        return GeneralApiFp(this.configuration).getRecentLanguageResource(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Pusher authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public postInfoResource(options?: any) {
        return GeneralApiFp(this.configuration).postInfoResource(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 문제 그룹 목록 보기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemGroupList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/group/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 문제 그룹 목록 보기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseProblemGroupList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupListResponse>> {
            const localVarAxiosArgs = await GroupApiAxiosParamCreator(configuration).getCourseProblemGroupList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 문제 그룹 목록 보기
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemGroupList(options?: any): AxiosPromise<GroupListResponse> {
            return GroupApiFp(configuration).getCourseProblemGroupList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 문제 그룹 목록 보기
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getCourseProblemGroupList(options?: any) {
        return GroupApiFp(this.configuration).getCourseProblemGroupList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProblemApi - axios parameter creator
 * @export
 */
export const ProblemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 문제 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemView: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProblemView.');
            }
            const localVarPath = `/problem/view/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 문제 제출하기
         * @param {number} id 
         * @param {SubmitFiles} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProblemSubmitView: async (id: number, payload: SubmitFiles, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postProblemSubmitView.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling postProblemSubmitView.');
            }
            const localVarPath = `/problem/submit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof payload !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(payload !== undefined ? payload : {})
                : (payload || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProblemApi - functional programming interface
 * @export
 */
export const ProblemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 문제 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemView(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await ProblemApiAxiosParamCreator(configuration).getProblemView(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 문제 제출하기
         * @param {number} id 
         * @param {SubmitFiles} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProblemSubmitView(id: number, payload: SubmitFiles, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSubmitResponse>> {
            const localVarAxiosArgs = await ProblemApiAxiosParamCreator(configuration).postProblemSubmitView(id, payload, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProblemApi - factory interface
 * @export
 */
export const ProblemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 문제 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemView(id: number, options?: any): AxiosPromise<Problem> {
            return ProblemApiFp(configuration).getProblemView(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 문제 제출하기
         * @param {number} id 
         * @param {SubmitFiles} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProblemSubmitView(id: number, payload: SubmitFiles, options?: any): AxiosPromise<ProblemSubmitResponse> {
            return ProblemApiFp(configuration).postProblemSubmitView(id, payload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProblemApi - object-oriented interface
 * @export
 * @class ProblemApi
 * @extends {BaseAPI}
 */
export class ProblemApi extends BaseAPI {
    /**
     * 문제 보기
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProblemApi
     */
    public getProblemView(id: number, options?: any) {
        return ProblemApiFp(this.configuration).getProblemView(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 문제 제출하기
     * @param {number} id 
     * @param {SubmitFiles} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProblemApi
     */
    public postProblemSubmitView(id: number, payload: SubmitFiles, options?: any) {
        return ProblemApiFp(this.configuration).postProblemSubmitView(id, payload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubmissionApi - axios parameter creator
 * @export
 */
export const SubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 내가 한 제출 목록 보기
         * @param {any} [cursor] 현재 페이지의 맨 위에 있는 제출 번호
         * @param {any} [problemId] 문제 번호
         * @param {any} [perPage] 페이지당 제출 개수. 1 이상 50 이하여야 하며, 범위 바깥이면 적절히 clamp됩니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissionListView: async (cursor?: any, problemId?: any, perPage?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/submission/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (problemId !== undefined) {
                localVarQueryParameter['problem_id'] = problemId;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 내가 한 제출 답안 세부 사항 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissionView: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProblemSubmissionView.');
            }
            const localVarPath = `/submission/view/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionApi - functional programming interface
 * @export
 */
export const SubmissionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 내가 한 제출 목록 보기
         * @param {any} [cursor] 현재 페이지의 맨 위에 있는 제출 번호
         * @param {any} [problemId] 문제 번호
         * @param {any} [perPage] 페이지당 제출 개수. 1 이상 50 이하여야 하며, 범위 바깥이면 적절히 clamp됩니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSubmissionListView(cursor?: any, problemId?: any, perPage?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSubmissionListResponse>> {
            const localVarAxiosArgs = await SubmissionApiAxiosParamCreator(configuration).getProblemSubmissionListView(cursor, problemId, perPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 내가 한 제출 답안 세부 사항 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemSubmissionView(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSubmission>> {
            const localVarAxiosArgs = await SubmissionApiAxiosParamCreator(configuration).getProblemSubmissionView(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubmissionApi - factory interface
 * @export
 */
export const SubmissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 내가 한 제출 목록 보기
         * @param {any} [cursor] 현재 페이지의 맨 위에 있는 제출 번호
         * @param {any} [problemId] 문제 번호
         * @param {any} [perPage] 페이지당 제출 개수. 1 이상 50 이하여야 하며, 범위 바깥이면 적절히 clamp됩니다.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissionListView(cursor?: any, problemId?: any, perPage?: any, options?: any): AxiosPromise<ProblemSubmissionListResponse> {
            return SubmissionApiFp(configuration).getProblemSubmissionListView(cursor, problemId, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 내가 한 제출 답안 세부 사항 보기
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemSubmissionView(id: number, options?: any): AxiosPromise<ProblemSubmission> {
            return SubmissionApiFp(configuration).getProblemSubmissionView(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubmissionApi - object-oriented interface
 * @export
 * @class SubmissionApi
 * @extends {BaseAPI}
 */
export class SubmissionApi extends BaseAPI {
    /**
     * 내가 한 제출 목록 보기
     * @param {any} [cursor] 현재 페이지의 맨 위에 있는 제출 번호
     * @param {any} [problemId] 문제 번호
     * @param {any} [perPage] 페이지당 제출 개수. 1 이상 50 이하여야 하며, 범위 바깥이면 적절히 clamp됩니다.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public getProblemSubmissionListView(cursor?: any, problemId?: any, perPage?: any, options?: any) {
        return SubmissionApiFp(this.configuration).getProblemSubmissionListView(cursor, problemId, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 내가 한 제출 답안 세부 사항 보기
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public getProblemSubmissionView(id: number, options?: any) {
        return SubmissionApiFp(this.configuration).getProblemSubmissionView(id, options).then((request) => request(this.axios, this.basePath));
    }
}


