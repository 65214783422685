












import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class PDFView extends Vue {
  @Prop({ required: true }) url!: string;

  get src(): string {
    return `/pdfjs-dist/web/viewer.html?file=${ this.url }`;
  }
}
