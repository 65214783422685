















import { Vue, Component } from "vue-property-decorator";
import * as api from "../api/gen";
import apiConfig from "../api/config";
import SubmissionView from "../components/SubmissionView.vue";

@Component<Submission>({
  components: {
    SubmissionView,
  },
  metaInfo() {
    const loaded = this.loaded;
    const id = this.id;

    return {
      title: loaded ? `${id}번 제출 현황` : '제출 현황',
    };
  },
})
export default class Submission extends Vue {
  private id = 0;
  private submission?: api.ProblemSubmission = undefined;

  private errorMessage = '';

  private loaded = false;
  private failed = false;

  created(): void {
    this.id = Number(this.$route.params.id);

    const submissionApi = new api.SubmissionApi(apiConfig);
    submissionApi.getProblemSubmissionView(this.id)
    .then((response) => {
      const submission: api.ProblemSubmission = response.data;

      this.submission = submission;

      this.loaded = true;
    })
    .catch((reason) => {
      this.failed = true;

      if(reason.response && typeof reason.response.status == 'number') {
        const errorCode: number = reason.response.status;

        if(401 === errorCode) {
          if(!this.$store.getters.isAuth) {
            this.$router.push({
              name: 'login',
              query: {
                next: this.$route.fullPath,
              },
            });
            return;
          }
          
          this.errorMessage = '접근 권한이 없습니다.';
          return;
        }

        if(404 === errorCode) {
          this.errorMessage = '제출을 찾을 수 없습니다.';
          return;
        }
      }

      this.$store.commit('showErrorSnackbar', reason);
    })
  }
}
