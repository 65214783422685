import Vue from "vue";
import Vuex from "vuex";

import { Auth } from "@aws-amplify/auth";

Vue.use(Vuex);

interface Information {
  user: object | null;

  errorSnackbar: boolean;
  errorText: string | null;
}

export default new Vuex.Store({
  state: {
    user: null,
    errorSnackbar: false,
    errorText: null,

    defaultTitleTemplate: (titleChunk: string): string => {
      let siteName = "OI Korea Judge";
      if (process.env.NODE_ENV !== "production") {
        siteName += ` (${process.env.NODE_ENV})`;
      }
      return titleChunk
            ? `${titleChunk} | ${siteName}`
            : siteName;
    },
  } as Information,
  mutations: {
    showErrorSnackbar (state: Information, text: string) {
      state.errorSnackbar = true;
      state.errorText = text;
    },
  },
  actions: {
    loadAuth() {
      return Auth.currentUserPoolUser()
        .then(authData => {
          if (authData) {
            this.state.user = authData;
          }
        })
        .catch(() => {
          this.state.user = null;
        });
    },
  },
  modules: {
  },
  getters: {
    isAuth: (state): boolean => {
      return Boolean(state.user);
    },
  },
});
