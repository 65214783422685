var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('amplify-authenticator',[_c('amplify-sign-in',{attrs:{"slot":"sign-in","header-text":"로그인"},slot:"sign-in"}),_c('amplify-sign-up',{attrs:{"slot":"sign-up","header-text":"회원가입"},domProps:{"formFields":[
        { type: 'username' },
        { type: 'password' },
        { type: 'email' },
        { type: 'custom:doyouagree', label: '가입할 시 페이지 최하단의 개인정보취급방침 및 약관에 동의하게 됩니다. 모두 읽고 동의하셨다면 \'동의\'를 입력해 주세요.' }
      ]},slot:"sign-up"}),_c('amplify-forgot-password',{attrs:{"slot":"forgot-password","username-alias":"email"},domProps:{"formFields":[
        { type: 'email', label: '회원가입 시 입력한 이메일 주소 *', placeholder: '이메일 주소를 입력하세요.' }
      ]},slot:"forgot-password"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }