






import { Vue, Component } from "vue-property-decorator";

import marked from "marked";
import text from "!!raw-loader!../../public/PrivacyPolicy.md";

@Component({
  metaInfo() {
    return {
      title: '개인정보처리방침',
    };
  },
})
export default class PrivacyPolicy extends Vue {
  get compiledMd() {
    return marked(text, { breaks: true });
  }
}
