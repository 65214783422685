





















import { Vue, Component } from "vue-property-decorator";

import * as api from "../api/gen";
import apiConfig from "../api/config";

interface ProblemTab {
  name: string;
  route: string;
  isDisabled?: boolean;
}

@Component<Problem>({
  metaInfo() {
    const defaultTitleTemplate = this.$store.state.defaultTitleTemplate;

    return {
      title: '문제',
      titleTemplate: this.loaded && this.problem
                    ? defaultTitleTemplate(`%s - ${this.problem.title}`)
                    : defaultTitleTemplate,
    };
  },
})
export default class Problem extends Vue {
  loading = false;
  loaded = false;
  problemId = 0;
  problem?: api.Problem;

  tabs: Array<ProblemTab> = [];
  currentTab?: number;

  showProblem() {
    this.tabs = [
      { name: "문제 보기", route: `/problem/${this.problemId}/view`},
      {
        name: "제출",
        route: `/problem/${this.problemId}/submit`,
        isDisabled: !this.problem || true !== this.problem.submit_enabled,
      },
      { name: "풀이", route: `/problem/${this.problemId}/solution`},
      { name: "제출 목록", route: `/problem/${this.problemId}/submissions`},
    ];
    this.currentTab = 0;
  }

  created() {
    this.loading = true;
    this.problemId = Number(this.$route.params.id);

    const ProblemApi = new api.ProblemApi(apiConfig);
    ProblemApi.getProblemView(this.problemId).then(response => {
      this.problem = response.data;
      this.loading = false;
      this.loaded = true;
      this.showProblem();
    })
    .catch(reason => {
      this.loading = false;
      this.loaded = false;
      this.$store.commit('showErrorSnackbar', reason);
    });
  }
}
