var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.submissionsHeader,"items":_vm.listItems,"items-per-page":_vm.listItems.length,"hide-default-footer":"","disable-sort":"","mobile-breakpoint":"0","no-data-text":_vm.noSubmissionMessage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
      name: 'submission',
      params: { id: item.id }
    }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.course_problem",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{
      name: 'problemView',
      params: { id: item.course_problem.id }
    }}},[_vm._v(" "+_vm._s(item.course_problem.title)+" ")])]}},{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeString")(item.time))+" ")]}},{key:"item.language",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.language_name || item.language.name)+" ")]}},{key:"item.score",fn:function(ref){
    var item = ref.item;
return [_c('ScoreBar',{attrs:{"user_score":item.score,"accepted_score":item.accepted_score}})]}},{key:"item.execution_time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("usedTimeString")(item.max_execution_time_in_seconds))+" ")]}},{key:"item.memory",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("usedMemoryString")(item.max_used_memory_in_kb))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }