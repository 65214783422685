













































































































import { Vue, Prop, Component, Ref, Watch } from "vue-property-decorator";

import SubmissionsTable from "@/components/SubmissionsTable.vue";

import * as api from "../api/gen";
import apiConfig from "../api/config";

import * as Pusher from "../lib/pusher";

interface ProblemItem {
  text?: string;
  value?: string;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

interface Params {
  cursor?: string;
  problemId?: string;
  perPage?: string;
  //options?: any;
}

@Component({
  components: {
    SubmissionsTable,
  },
})
export default class SubmissionsList extends Vue {
  @Prop() urlParams!: Params;
  @Prop() disableProblemSelect!: boolean;

  @Ref() perPageSelectElement!: HTMLInputElement;
  @Ref() problemSelectElement!: HTMLInputElement;

  loading = false;
  loadingFailed = false;
  loadingMessage = "제출 목록을 로드하는 중입니다.";
  loadingFailedMessage = "제출 목록 로드에 실패했습니다.";
  noSubmissionMessage = "제출이 없습니다.";
  searchButtonMessage = "검색";

  params: Params = {};

  private pusher?: Pusher.Pusher;

  setProblemId(val?: null | string) {
    const n = Number(val);
    if (val === null || val === "" || !Number.isInteger(n)) {
      return undefined;
    } else {
      return String(n);
    }
  }

  setPerPage(val?: null | string) {
    const n = Number(val);
    if (val === null || val === "" || !Number.isInteger(n) || n < 1 || n > 50) {
      return undefined;
    } else {
      return String(n);
    }
  }

  setCursor(val?: null | string | number) {
    if (typeof val === "number") {
      return String(val);
    } else {
      const n = Number(val);
      if (val === null || val === "" || !Number.isInteger(n)) {
        return undefined;
      } else {
        return String(n);
      }
    }
  }

  problemSelect: null | string = null;
  problemMessage = "문제";
  problemsList: Array<ProblemItem> = [];
  noProblemMessage = "해당하는 문제가 없습니다.";
  problemError: Array<string> = [];
  problemErrorMessage = "문제 목록을 로드할 수 없습니다.";

  perPageSelect: null | string = null;
  perPageMessage = "한 페이지당 개수";
  perPageSelectList = ["10", "25", "50"];
  perPageErrorMessage = "1 이상 50 이하의 정수를 입력해주세요.";
  perPageRules = [
    (val?: null | string) => {
      const n = Number(val);
      if (val == null || val == "") {
        return true;
      } else if (!Number.isInteger(n) || n < 1 || n > 50) {
        return this.perPageErrorMessage;
      } else {
        return true;
      }
    },
  ];

  firstButtonMessage = "처음";
  prevButtonMessage = "이전";
  nextButtonMessage = "다음";

  nextCursor = 0;
  prevCursor = 0;

  submissionsList: Array<api.ProblemSubmissionSummary> = [];
  submissionsHeader = [
    { text: "제출 번호", value: "id" },
    { text: "제출 시각", value: "time" },
    { text: "문제", value: "course_problem" },
    { text: "제출 언어", value: "language_name" },
    { text: "채점 상황", value: "state_message" },
    { text: "채점 결과", value: "score", width: "20%" },
    { text: "실행 시간", value: "execution_time" },
    { text: "메모리", value: "memory" },
  ];

  loadProblems() {
    const groupsApi = new api.GroupApi(apiConfig);
    groupsApi.getCourseProblemGroupList()
      .then(response => {
        if (response.data.groups) {
          const groups: Array<api.CourseProblemGroup> = response.data.groups;

          for (const group of groups) {
            this.problemsList.push({ header: group.title });
            if (group.problems) {
              for (const problem of group.problems) {
                this.problemsList.push({
                  text: problem.title,
                  value: String(problem.id),
                });
              }
            }
            this.problemsList.push({ divider: true });
          }
        }
      })
      .catch(() => {
        this.problemError = [ this.problemErrorMessage ];
      });
  }

  loadSubmissions() {
    if(this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
    }
    
    this.loading = true;
    this.loadingFailed = false;

    const toNumber = (v?: string) => (Number.isInteger(Number(v))? Number(v) : undefined);

    const SubmissionApi = new api.SubmissionApi(apiConfig);
    SubmissionApi.getProblemSubmissionListView(
      toNumber(this.params.cursor),
      toNumber(this.params.problemId),
      toNumber(this.params.perPage),
    )
      .then((response) => {
        this.loading = false;
        this.prevCursor = response.data.prev_cursor || 0;
        this.nextCursor = response.data.next_cursor || 0;
        this.submissionsList = response.data.submissions || [];

        Pusher.getPusher(apiConfig)
        .then((pusher) => {
          this.pusher = pusher;

          if(!this.pusher) {
            return;
          }

          Pusher.updateSubmissions(apiConfig, this.submissionsList, this.pusher, undefined, () => {
            this.$store.commit('showErrorSnackbar', "제출 상황 실시간 업데이트에 실패했습니다.");
          });
        })
        .catch(() => {
          this.$store.commit('showErrorSnackbar', "제출 상황 실시간 업데이트에 실패했습니다.");
        });
      })
      .catch(() => {
        this.loading = false;
        this.loadingFailed = true;
      });
  }

  updateFromUrl() {
    this.params.problemId = this.setProblemId(this.urlParams.problemId);
    this.params.perPage = this.setPerPage(this.urlParams.perPage);
    this.params.cursor = this.setCursor(this.urlParams.cursor);

    this.perPageSelect = this.params.perPage || null;
    this.problemSelect = this.params.problemId || null;
  }

  // default가 1인 경우 loadSubmissions()를 호출
  // 상단 app bar에서 "제출 목록" 버튼을 클릭하면 default가 1로 설정됨
  @Watch('$route.query')
  queryUpdated() {
    if (this.$route.query.default === "1") {
      this.updateFromUrl();
      this.loadSubmissions();
    }
  }

  async updateParams() {
    await this.problemSelectElement.blur();
    await this.perPageSelectElement.blur();

    const oldQuery: Params = this.$route.query;

    this.params.problemId = this.setProblemId(this.problemSelect);
    // 문제별 제출 목록 페이지에서는 problemId를 URL에 반영하지 않음
    if (this.disableProblemSelect) {
      oldQuery.problemId = this.params.problemId;
    }
    this.params.perPage = this.setPerPage(this.perPageSelect);

    // 문제가 바뀌면 커서를 0으로 설정
    if (oldQuery.problemId != this.params.problemId) {
      this.params.cursor = undefined;
    }

    this.$router.replace({ query: {
      cursor: this.params.cursor,
      // 문제별 제출 목록 페이지에서는 problemId를 URL에 반영하지 않음
      problemId: (this.disableProblemSelect
        ? undefined
        : this.params.problemId),
      perPage: this.params.perPage,
    }})
      .then(() => {
        this.loadSubmissions();
      })
      .catch(err => {
        if (err.name === "NavigationDuplicated") {
          this.loadSubmissions();
        } else {
          console.error(err);
        }
      });
  }

  firstPage() {
    this.params.cursor = this.setCursor(undefined);
    this.updateParams();
  }

  prevPage() {
    this.params.cursor = this.setCursor(this.prevCursor);
    this.updateParams();
  }

  nextPage() {
    this.params.cursor = this.setCursor(this.nextCursor);
    this.updateParams();
  }

  mounted() {
    this.loadProblems();
    this.updateFromUrl();
    this.loadSubmissions();
  }
}
